import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "privacypolicy": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "index": {
        "height": "",
        "width": "",
        "max-height": "7000px",
        "font": "normal 900 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "about-us": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "modality": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "certification": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "insurance": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "operations": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    },
    "contacts": {
        "height": "",
        "width": "",
        "max-height": "7000px"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
